import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Fade } from '@material-ui/core';
import { CardapioHeader } from './cardapio-header';
import { CardapioHeaderNavBar } from './cardapio-navbar';
import {
  Sessoes,
  Sessao,
  VisualizarProdutoModal,
} from 'modulos/cardapio/componentes';
import { useParams } from 'react-router-dom';
import { useCardapioApi, useMasterApi, useCardapioLogica, EnumTipoScanner } from './hooks';
import { CategoriasCarrosel } from 'modulos/cardapio/componentes/categorias';
import { CategoriaCarroselInstancia } from 'modulos/cardapio/componentes/categorias/categorias-carrosel/categorias-carrosel-instancia';
import { CardapioProps } from './cardapio-props';
import { useStyles } from './cardapio-styles';
import { SessoesInstancia } from 'modulos/cardapio/componentes/produtos';
import { CardapioFooter } from './cardapio-footer';
import { MasterPagina } from 'modulos';
import { EditarDados } from 'modulos/cardapio/paginas/edicao/edicao-header/editar-dados';
import { MergedCardapio } from 'api/merged/merged-master-cardapio';
import { CardapioSacola } from '.';
import { ButtonSacola } from './cardapio-sacola/componentes';
import { Carregando, QRCodeModal, useNotificacoes } from 'componentes';
import { CardapioResumoPedido } from './cardapio-resumo-pedido';
import { isEmpty } from 'lodash';
import { isValidUUIDV4 } from 'utils/is-valid-id';
import { TemaModel } from 'api/cardapio/models/tema/tema-model';
import { DialogScannerComanda } from './cardapio-scanner-comanda';
import { usePedidos } from 'componentes/providers/pedidos-provider';
import { ConfigEmpresaModel, EmpresaModel } from 'api/cardapio/models/empresa/empresa-model';
import { CardapioDadosProps } from './cardapio-header/cardapio-header-props';
import { HorarioModel } from 'api/cardapio/models/horario/horario-model';
import { DialogAdicionarProdutoComSubItem } from 'modulos/cardapio/componentes/produtos/dialog-adicionar-produto-com-subitem/dialog-adicionar-produto-com-subitem';
import { useMovProd } from 'hooks/mov-prod';
import classNames from 'classnames';

export const Cardapio = ({ somenteLeitura, configurando, gerarQrCode, contextoSelecionado }: CardapioProps) => {

  // DEPENDENCIAS
  const classes = useStyles();

  // STATES, REFS E PARAMS
  const sessoes = React.useRef<SessoesInstancia>(null);
  const pesquisa = React.useRef<HTMLDivElement | null>(null);
  const menuNavRef = React.useRef<HTMLDivElement | null>(null);
  const refCategorias = React.useRef<CategoriaCarroselInstancia>(null);
  const { empresaId } = useParams<{ empresaId: string }>()
  const { adicionarEmpresa } = useParams<{ adicionarEmpresa: string }>();
  const [statusPedido, setStatusPedido] = useState<boolean>(false);
  const [values, setValues] = useState<TemaModel>(new TemaModel());
  const [redefinir, setRedefinir] = useState<boolean>(false);
  const { getPedido, setCodComanda, handlePedido, handlePedidoComanda } = usePedidos();
  // const { setCredentials } = useCheckout();
  const { showErrorMessage, showSuccessMessage } = useNotificacoes()
  const { adicionarProdutoComSubItem, editarProdutoComSubItem } = useMovProd()

  // ID MESA NA URL
  let path = window.location.pathname.split('/');
  const mesaId = path[2];

  // GET LOCALSTORAGE

  // REQUISIÇÕES
  const { dados: apiDados, operacoes: api } = useCardapioApi(empresaId);
  const { dados: apiMasterDados } = useMasterApi(empresaId);

  const cardapioDados: CardapioDadosProps = useMemo(() => ({
    empresa: apiMasterDados.data?.empresa ?? new EmpresaModel(),
    configuracoes: apiMasterDados.data?.configuracoes ?? new TemaModel(),
    horarioFuncionamento: apiMasterDados.data?.horarioFuncionamento ?? new HorarioModel(),
    configuracoesEmpresa: apiMasterDados.data?.configuracoesEmpresa ?? [],
    finalizadoras: apiMasterDados.data?.finalizadoras ?? []
  }), [apiMasterDados.data?.configuracoes, apiMasterDados.data?.configuracoesEmpresa, apiMasterDados.data?.empresa, apiMasterDados.data?.finalizadoras, apiMasterDados.data?.horarioFuncionamento])
  const configEmp: ConfigEmpresaModel[] = useMemo(() => [], [])

  const { dados: logicaDados, operacoes: logica } = useCardapioLogica(
    sessoes,
    refCategorias,
    pesquisa,
    menuNavRef,
    apiDados.sessoes.data || [],
    api,
    empresaId,
    mesaId,
    somenteLeitura,
    {
      empresa: cardapioDados?.empresa ?? new EmpresaModel(),
      configuracoes: cardapioDados?.configuracoes,
      horarios: cardapioDados?.horarioFuncionamento,
    }
  );

  const produtos = useMemo(() => apiDados.sessoes.data.map(x => x.produtos).reduce((arr, obj) => arr.concat(obj), []), [apiDados.sessoes.data])

  const verificarEmpresa = useCallback(() => {

    const produtosImg = apiDados.sessoes.data.map(x => x.produtos).reduce((arr, obj) => arr.concat(obj.map((y: any) => ({
      produtoId: y.produtoId,
      imagemUrl: y.imagemUrl
    }))), [])

    const imgsJson = JSON.stringify(produtosImg)

    localStorage.setItem('ProdutoImgs', imgsJson)
  }, [apiDados.sessoes.data])

  const verificarMesa = useCallback(() => {
    let idValido: boolean = isValidUUIDV4(mesaId);
    if (idValido) {
      return localStorage.removeItem('tokenMasterUsuario');
    }
  }, [mesaId]);

  useEffect(() => {
    verificarEmpresa();
    verificarMesa();
  }, [verificarEmpresa, verificarMesa])

  const carregandoCategoria =
    apiMasterDados.isLoading || apiDados.sessoes.isLoading || apiDados.sessoes.isFetching;

  const abrirResumoPedido = () => {
    if (cardapioDados.configuracoes.tipoUsoCardapio === 1) {
      logica.abrirScannerComanda()
      logica.definirTipoScanner(EnumTipoScanner.VisualizarPedido)
      return
    }
    logica.abrirGarcom()
  }

  const handleCodigo = async (codigo: string) => {
    const prefixo = cardapioDados.configuracoesEmpresa.find(x => x.codigo === 804)
    if (prefixo && prefixo.valor && codigo.startsWith(prefixo.valor)) {
      codigo = codigo.slice(prefixo.valor.length)
    }
    if (logicaDados.tipoScanner === EnumTipoScanner.VisualizarPedido) {
      logica.abrirGarcom()
      await getPedido(codigo)
      return
    }
    setCodComanda(codigo)

    if (cardapioDados.configuracoes.disponibilizarPagamentoFinalizacao) {
      logica.abrirModalFormaPag()
      return;
    }

    try {
      await handlePedidoComanda(codigo)
    } catch (err: any) {
      showErrorMessage(err.message)
    }
  }

  const handleFazerPedido = useCallback(async () => {
    logica.fecharModalCliente()
    logica.fecharSacola()

    if (cardapioDados.configuracoes.tipoUsoCardapio === 1) {
      logica.abrirScannerComanda();
      logica.definirTipoScanner(EnumTipoScanner.PrepararPedido)
      return
    }

    if (cardapioDados.configuracoes.disponibilizarPagamentoFinalizacao) {
      logica.abrirModalFormaPag()
      return;
    }
    try {
      await handlePedido()
      showSuccessMessage('Pedido realizado com sucesso!')
    } catch (err: any) {
      showErrorMessage(err.message)
    }
  }, [cardapioDados.configuracoes.disponibilizarPagamentoFinalizacao, cardapioDados.configuracoes.tipoUsoCardapio, handlePedido, logica, showErrorMessage, showSuccessMessage]);

  const permiteEntregaRetirada = cardapioDados?.configuracoes.permiteEntrega || cardapioDados.configuracoes.permiteRetirada

  const clienteEstaNaLoja = useCallback((): boolean => {
    //verificar se tem o mesaId e se é válido
    if (!isEmpty(mesaId) && isValidUUIDV4(mesaId))
      return true;

    return false;
  }, [mesaId]);

  if (carregandoCategoria) {
    return (
      <Fade in={true}>
        <div>
          <Carregando
            titulo={
              !apiMasterDados.isLoading
                && (apiDados.sessoes.isLoading || apiDados.sessoes.isFetching)
                ? 'Carregando os produtos'
                : "Preparando Cardápio"}
            mensagem={"Aguarde enquanto organizamos as informações!"}
            modelo="fixed"
            carregando
          />
        </div>
      </Fade>
    )
  }

  return (
    <MasterPagina>
      <React.Fragment>
        <CardapioHeaderNavBar
          empresaMerged={{
            ...cardapioDados,
            horarios: cardapioDados!.horarioFuncionamento
          } as MergedCardapio}
          mesaId={mesaId}
          somenteLeitura={somenteLeitura}
          logout={logica.logout}
          categorias={apiDados.sessoes.data}
          referencia={menuNavRef}
          navegarEditar={logica.navegarParaEdicao}
          quandoSacolaClick={logica.abrirSacola}
          quandoGarcomClick={abrirResumoPedido}
          setConfigurando={logica.configurandoWrapper}
          setGerarQrCode={logica.gerarQrCode}
          configurando={configurando || false}
          mostarQrCode={gerarQrCode || false}
          termoPesquisa={logicaDados.termoPesquisa}
          categoriaSelecionada={logicaDados.categoriaSendoExibida}
          contextoSelecionado={contextoSelecionado}
          quandoPesquisar={logica.quandoTermoForDigitadoNoNavbar}
          categoriaClick={logica.categoriaClick!}
          limparTermo={logica.limparTermoPesquisa}
          logar={logica.logar}
          setStatusPedido={setStatusPedido}
          statusPedido={statusPedido}
        />
        {!configurando && gerarQrCode ? (
          <></>
        ) : (
          <div ref={logicaDados.header}>
            <CardapioHeader
              atualizando={false}
              somenteLeitura={somenteLeitura}
              cardapioDados={cardapioDados!}
              className={classes.header}
              configurando={configurando || false}
              mesaId={mesaId}
              setValueProps={setValues}
              redefinir={redefinir}
            />
            {!configurando && (clienteEstaNaLoja() || permiteEntregaRetirada) && (
              <div className={classes.btnSacola}>
                <ButtonSacola
                  empresaId={empresaId!}
                  quandoClicado={logica.abrirSacola}
                  colorBadge="secondary"
                  corLogo="textPrimary"
                  size="35"
                />
              </div>
            )}
            {!configurando && (
              <Container fixed>
                {logicaDados.pesquisando && !configurando && (
                  <div ref={pesquisa}>
                    <Sessao
                      somenteLeitura={somenteLeitura}
                      sessaoDeBusca
                      carregando={logicaDados.carregandoPesquisa}
                      destaque={`"${logicaDados.termoPesquisa}"`}
                      className={classes.pesquisa}
                      sessao={{
                        nome: 'Buscando por: ',
                        produtos: logicaDados.resultadoPesquisa.filter(x => x.tipo === 0),
                        ativo: true,
                        breadCrumbs: '',
                        categoriaPaiId: '',
                        contratoId: '',
                        cor: '',
                        descricao: '',
                        id: '',
                      }}
                      onProdutoClick={logica.abrirEdicaoProduto}
                      adicionarProdutoClick={logica.abrirAdicaoProduto}
                    />
                  </div>
                )}
                <CategoriasCarrosel
                  ref={refCategorias}
                  somenteLeitura={somenteLeitura}
                  categorias={apiDados.sessoes.data}
                  carregando={carregandoCategoria}
                  onCategoriaClick={logica.categoriaClick}
                />
              </Container>
            )}
          </div>
        )}

        {!configurando && gerarQrCode ? (
          <Container className={classNames(classes.container, classes.containerQRCode)} fixed>
            <QRCodeModal
              aberto={gerarQrCode || false}
              empresaId={empresaId}
              nomeEmpresa={cardapioDados!.empresa.nomeFantasia}
              infoEmpresa={cardapioDados!.configuracoes.informacaoAdicional}
              tema={cardapioDados.configuracoes!}
              empresaMaster={cardapioDados!.empresa}

            />
          </Container>
        ) : (!configurando && (
          <>
            <Sessoes
              ref={sessoes}
              carregando={apiDados.sessoes.isLoading || apiDados.sessoes.isFetching}
              sessoes={apiDados.sessoes.data}
              somenteLeitura={somenteLeitura}
              className={classes.sessao}
              quandoSessaoForVisualizada={logica.quandoSessaoForExibida}
              adicionarProdutoClick={logica.abrirAdicaoProduto}
              onProdutoClick={logica.abrirEdicaoProduto}
            />
            <CardapioSacola
              empresaDados={
                {
                  ...cardapioDados,
                  horarios: cardapioDados!.horarioFuncionamento
                } as MergedCardapio
              }
              empresaId={empresaId}
              logica={logica}
              logicaDados={logicaDados}
              abrirModalCliente={logica.abrirModalCliente}
              statusPedido={statusPedido}
              tema={cardapioDados.configuracoes ?? new TemaModel()}
              mesaId={mesaId}
              sacolaAberta={logicaDados.sacola}
              quandoFechado={logica.fecharSacola}
              handleFazerPedido={handleFazerPedido}
              configEmp={configEmp}
              produtos={produtos}
            />
          </>
        )
        )}

        {mesaId !== undefined && (
          <>
            <Container fixed>
              <CardapioResumoPedido
                empresaDados={
                  {
                    ...cardapioDados!,
                    horarios: cardapioDados!.horarioFuncionamento
                  }
                }
                mesaId={mesaId}
                empresaId={empresaId}
                garcomAberto={logicaDados.garcom}
                quandoFechado={logica.fecharGarcom}
              />
            </Container>
          </>
        )}

        {configurando && (
          <EditarDados
            empresaId={empresaId}
            aberto={configurando || false}
            quandoForFechado={logica.logar}
            adicionarEmpresa={adicionarEmpresa}
            logout={logica.logout}
            setValues={setValues}
            values={values}
            redefinir={redefinir}
            setRedefinir={setRedefinir}
          />
        )}

        <CardapioFooter
          tema={cardapioDados!.configuracoes}
          empresa={cardapioDados!.empresa}
        />
        {logicaDados.produtoAberto && (
          <VisualizarProdutoModal
            aberto={logicaDados.produtoAberto}
            mesaId={mesaId}
            empresaDados={
              {
                ...cardapioDados!,
                horarios: cardapioDados!.horarioFuncionamento
              }
            }
            quandoForFechado={logica.fecharEdicaoProduto}
            produto={logicaDados.produto}
          ></VisualizarProdutoModal>
        )}
        {logicaDados.scannerComanda && (
          <DialogScannerComanda
            openned={logicaDados.scannerComanda}
            statusPedido={false}
            closeModal={logica.fecharScannerComanda}
            empresaDados={{
              ...cardapioDados!,
              horarios: cardapioDados!.horarioFuncionamento
            }}
            empresaId={empresaId}
            mesaId={mesaId}
            handleCodigo={handleCodigo}
          />
        )}
        {logicaDados.dialogSubitem && (
          <DialogAdicionarProdutoComSubItem
            aberto={logicaDados.dialogSubitem}
            adicionarProdutoComSubItem={adicionarProdutoComSubItem}
            editarProdutoComSubItem={editarProdutoComSubItem}
            fechar={() => logica.setDialogSubItem(false)}
            produto={logicaDados.produto}
          />
        )}
      </React.Fragment >
    </MasterPagina >
  );
};